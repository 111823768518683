import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import BlockContent from "../components/BlockContent/BlockContent";
import BlockContentOutline from "../components/BlockContentOutline/BlockContentOutline";
import Breadcrumbs, { Breadcrumb } from "../components/Breadcrumbs/Breadcrumbs";
import Card from "../components/Card/Card";
import ContentWithRightAside from "../components/ContentWithRightAside/ContentWithRightAside";
import Credits from "../components/Credits/Credits";
import { GlobalGoalsResourceTagInfo } from "../components/GlobalGoalsResourceTagInfo/GlobalGoalsResourceTagInfo";
import QuickLinksBox, {
  DownloadButton
} from "../components/QuickLinksBox/QuickLinksBox";
import ExternalCollaboratorCredit from "../components/ExternalCollaboratorCredit/ExternalCollaboratorCredit";
import Text from "../components/Text/Text";
import GetText, { useString } from "../i18n/GetText";
import ContentLayout from "../layouts/ContentLayout/ContentLayout";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext, SanityCampaign } from "../model/common";
import { SanityGlobalGoalsResource } from "../model/dyb";
import { GlobalMenus } from "../model/menu";
import { urlForDocument } from "../urls";
import { SiteArea } from "../utils/analytics";
import { globalGoalsResourceWordDocUrl } from "../utils/downloads";

export const pageQuery = graphql`
  query DybGlobalGoalsPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityGlobalGoalsResource(_id: { eq: $_id }) {
      _id
      _type
      language
      title
      language
      goal
      slug {
        current
      }
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      sponsor {
        ...ExternalCollaborator
      }
      _rawContent(resolveReferences: { maxDepth: 5 })
      translationSponsors {
        ...TranslationSponsors
      }
      licence {
        ...Licence
      }
    }
    campaigns: allSanityCampaign(
      filter: { activeSiteAreas: { in: "doYourBit" } }
    ) {
      nodes {
        ...Campaign
      }
    }
  }
`;

const DybGlobalGoalsPage = ({
  pageContext,
  data: { menus, page, campaigns },
  location
}: DybGlobalGoalsPageProps) => {
  const { title, _rawContent, licence, translationSponsors } = page;
  const doYourBit = useString("do-your-bit", "do your :bit");
  const breadcrumbs: Breadcrumb[] = [
    {
      href: "../../",
      title: doYourBit
    },
    {
      title,
      href: urlForDocument(page)
    }
  ];
  return (
    <PageLayout
      siteArea={SiteArea.DO_YOUR_BIT}
      metadata={{
        page,
        title,
        alternates: pageContext.alternates
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <ContentLayout
        campaigns={campaigns.nodes}
        title={title}
        tags={<GlobalGoalsResourceTagInfo value={page} />}
        type={<GetText id="activity" fallback="Activity" />}
        breadcrumbs={<Breadcrumbs breadcrumbs={breadcrumbs} />}
        aside={
          <nav>
            <BlockContentOutline showOnMobile content={_rawContent} />
          </nav>
        }
        content={
          <Card type="main">
            <ContentWithRightAside
              main={<BlockContent content={_rawContent} />}
              right={
                <>
                  <QuickLinksBox title={<GetText id="quick-links" />}>
                    <Text variant="defaultLight" gutterBottom>
                      <GetText id="download-guide-word" />
                    </Text>
                    <DownloadButton
                      analyticsDownloadId={page.title}
                      textId="download-guide"
                      to={globalGoalsResourceWordDocUrl(page)}
                    />
                  </QuickLinksBox>
                  {page.sponsor && (
                    <ExternalCollaboratorCredit sponsor={page.sponsor} />
                  )}
                </>
              }
            />
            <Credits
              translationSponsors={translationSponsors}
              licence={licence}
            />
          </Card>
        }
      />
    </PageLayout>
  );
};

export default DybGlobalGoalsPage;

interface DybGlobalGoalsPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityGlobalGoalsResource;
    campaigns: { nodes: SanityCampaign[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
